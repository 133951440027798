import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"

const Ana = () => {
  return (
    <Layout>
      <SEO title="Ana" />
      <main className="page">
      <div className="profilePage">
            <div>
              <StaticImage
              src="../assets/images/ana.jpeg"
              alt="Ana"
              className="ana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
            </div>
            <div className="profileText">
                <h1>Ana Miletić<span className="kurziv"> prof. psych.</span></h1>
                <h3>Dobrodošli!</h3>
                <p>Veselim se upoznati te i biti tvoj suputnik u istraživanju i promjeni. Neće uvijek biti lagano, 
                  ali obećavam da smo u tome skupa.
                </p>
                <p>Upravo ta želja za dubinskim istraživanjem ljudske duše me je odvela na studij psihologije i kasnije 
                  gestalt psihoterapije. Kod odabira psihoterapijskog pravca posebno mi se dopalo što gestalt ide u dubinu 
                  i naglašava važnost da mi psihoterapeuti prolazimo osobnu psihoterapiju i iskusimo iz prve ruke kako je 
                  biti klijent i prolaziti promjenu koju nam terapija može donijeti. To mi je povećalo kapacitete da mogu 
                  biti prisutna s tobom, ali i suosjećanje za to što prolaziš i nadu da je promjena moguća. Moje iskustvo 
                  mi je pokazalo da kad se jednom nađemo na tom putu osvještavanja i povećanja osjećaja slobode, ne želimo stati. 
                  Tako se i ja nastavljam educirati u radu sa specifičnim temama u terapiji (<Link to="../Usluge/psihoterapija">EMDR</Link> tehnika rada sa traumom), 
                  ali i učim educirati druge kako postati psihoterapeuti.
                </p>
                <p>Prije nego sam skupa s Ivanom osnovala Apsihu kao mjesto gdje možemo kombinirati naša iskustva i znanja 
                  za dobrobit naših klijenata, dugo sam radila u organizacijskoj psihologiji. Surađivala sam s krasnim 
                  kolegama na kreiranju razvojnih programa na temu međuljudskih vještina, razvoju procesa u organizacijama 
                  koje će promovirati transparentnost i uvažavanje te mjerenju i upravljanju organizacijskom kulturom. I dalje 
                  sam u kontaktu sa tim područjem kroz <Link to="../Usluge/dobrobit-zaposlenika">EAP</Link> (Employee Assistance Program-e, u prijevodu programe podrške zaposlenicima) 
                  i well-being edukacije.
                </p>
                <p>Da ne pomisliš da je posao sav moj život, ima u njemu i mala i velika obitelj, prijatelji, pjevanje i glazba, vožnje 
                  biciklom i vježbanje, kuhanje i fina hrana, vino i kava, filmovi i serije, lakoća i težina.
                </p>
                <p>Ako se odlučiš doći k meni, tu sam. Provjeri koje sve usluge nudimo i veselim se da se susretnemo!
                </p>
            </div>
            <div className="backBtn">
              <Link to="/About/#ana" className="btn">
              &laquo; Povratak 
              </Link>
            </div>
            </div>  
      </main>
    </Layout>
  )
}

export default Ana
